import React from "react";
import Slider from "react-slick";
import "./testimonial.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      name: "Srushti Kakde",
      text: "Student Career Coach has helped me in every possible way. They have been there at every step, making the entire process feel like a cakewalk!",
      image: "anjali.jpg",
    },
    {
      name: "Anjali Pahuja",
      text: "Thanks to Student Career Coach, I got admission to Dr. DY Patil Institute in a very hassle-free manner. Highly recommend their services!",
      image: "test2.jpg",
    },
    {
      name: "Aditya Billore",
      text: "My entire journey has been simplified, and I could focus on PGDM preparations with ease. Student Career Coach is a life-saver!",
      image: "test3.jpg",
    },
    {
      name: "Apoorv Thakur",
      text: "The team at Student Career Coach provided me with career guidance and simplified my path. I'm truly thankful for their support.",
      image: "test4.jpg",
    },
  ];

  return (
    <div className="testimonial-section">
      <h2 className="section-title">What Our Students Say</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-image-container">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="testimonial-image"
              />
            </div>
            <div className="testimonial-content">
              <p className="testimonial-text">"{testimonial.text}"</p>
              <h4 className="testimonial-author">- {testimonial.name}</h4>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonial;
