import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  size: { width: 350, height:450 },  // Default size
};

const chatboxSlice = createSlice({
  name: 'chatbox',
  initialState,
  reducers: {
    reduceChatboxHeight: (state) => {
      state.size.height = state.size.height - 100;  // Reduce height by 100px
    },
  },
});

export const { reduceChatboxHeight } = chatboxSlice.actions;

export default chatboxSlice.reducer;
