import React from 'react';
import './Number.css';
import CountUp from 'react-countup';

const Numberbox = () => {
  return (
    <div>
      <div className='container-fluid mt-5 numbercolor p-5'>
        <div className='container '>
          <div className='row '>
           
<div className='col-md-3 mt-5 number-help p-4 ms-auto  '>
              <center>
                <h2 className='number-text'><CountUp end={100} duration={5} />+</h2>
                <h5 className='number-text1'>Colleges and Universities</h5>
              </center>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-3 mt-5 number-help p-4 ms-auto  '>
              <center>
                <h2 className='number-text'><CountUp end={50} duration={5} />+</h2>
                <h5 className='number-text1'>Courses</h5>
              </center>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-3 mt-5 number-help p-4 ms-auto '>
              <center>
                <h2 className='number-text'><CountUp end={100} duration={5} />+</h2>
                <h5 className='number-text1'>Counsellors</h5>
              </center>
            </div>
            <div className='col-md-1'></div>
            </div>
</div>
           </div>
          
           
           
          </div>
       
  );
};

export default Numberbox;
