import React from 'react';

import "./Developmentp.css";
const Developmentp = () => {
  return <div>
   <div className='container-fluid mt-5'>
    <div className='container'>
        <div className='row'>
        <h2 className='text-center develop-text mt-5'>Development <span className='develop-span'>Programs</span></h2>
        <p className='text-center develop-p mt-2'>Engage with the best of both Faculty and Industry experts to expand your horizons with our development programs</p>
        <div className='col-md-5 mt-3 ms-auto ms-5  developimage'>
            <h2 className='develop-text1 text-center'>Student Development Programs</h2>
<p className='text-center develop-p1 text-white'>Learn from India’s best Faculty and Industrialists in order to add that only Feather to your Intellectual Hat.</p>
        </div>
       
        <div className='col-md-5 mt-3 ms-auto  me-auto  developimage1'>
        <h2 className='develop-text1 text-center'>Faculty Development Programs</h2>
<p className='text-center develop-p1 text-white'>Current trends in all education fields can only be justified by the best of the faculty.Enroll for our Faculty Development Program to cater all current student needs.</p>
        </div>
        </div>

    </div>

   </div>
        </div>;
};

export default Developmentp;