import React from 'react';

import './Topnav.css';


const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'transparent',
        marginTop: '8px'
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'transparent',
        marginTop: '8px'
      }}
      onClick={onClick}
    />
  );
};

const Topnav = () => {
  const mainSliderSettings = {
    dots: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
   <div>
    <div class="container-fluid bg">
         <div class="row ">
            <div class="col-md-6  ms-auto me-3" >
               <p class="mt-2 me-auto" ><i class="bi bi-telephone"></i>+917017388084  <i class="bi bi-envelope ms-2 ">
                </i>studentcareer24@gmail.com</p>

               
            </div>
            <div class="col-md-4 ms-auto ">
               <p class="mt-2 "><i class="bi bi-facebook ms-2"></i> 
               <i class="bi bi-twitter ms-4"></i>
               <i class="fa fa-google-plus ms-2"></i>
               <i class='fab fa-linkedin-in ms-2'></i>
               <i class="bi bi-linkedin ms-2 "></i></p>

               
            </div>
            
         </div>
         
      </div>
    </div>
  );
};

export default Topnav;
