import React, { useState } from 'react';
import './About.css';
import Numberbox from './Numberbox';
import Vision from './Vision';
import Services from '../Services/Services';

const About = () => {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  return (
    <div>
      <div className="container-fluid about-image">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-4">
              <center>
                <h2 className="text-white aboutsmt">
                  <span className="about-span">Our adventure has been incredible,</span><br /> and we express our pride in it.
                </h2>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-4">
              <h2 className="why-text1">About <span className="why-span">Us</span></h2>
              <p className="why-p11 mt-3">
                Every student requires some assistance, a bit of encouragement, and someone who has faith in their potential to achieve success.
              </p>
              <p className="why-p11 mt-3">
                Student Career Coach is an emerging venture under the parent organization Career Leader Educational Services. Career Leader is a college admission consultancy firm that specializes in education counseling, admissions guidance, and professional opportunity assistance throughout India.
              </p>
              <p className="why-p11 mt-3">
                Since its inception as a college admissions counselor, Career Leader has gained widespread recognition for effectively shaping students' futures in management, engineering, medicine (both domestic and international), law, architecture, and distance learning education. With the rise of the digital age, our consultancy aims to carry this legacy forward through a digital platform—Student Career Coach.
              </p>
              {showMore && (
                <span className="mt-3 why-p11">
                  Often, students lack proper guidance and end up missing out on their preferred colleges. Student Career Reach is a free digital platform designed to be a personal guide for students, right at their fingertips. It acts as a beacon of light for confused and aspiring candidates, aiming to provide the right direction for shaping their professional paths. This platform helps students seize opportunities and make the most of their future endeavors.
                  <br />
                  <p className="mt-3">
                    With the Student Career Coach portal, we aim to provide graduating students with a student-focused platform that offers comprehensive, attractive solutions. Every student encounters numerous uncertainties when selecting the right college for higher education. Through this service, we aspire to become the leading admission consultancy in India.
                  </p>
                  <p className="mt-3">
                    The Student Career Coach portal provides a clear and detailed analysis of various colleges across the nation. Users can navigate a structured process to choose from a selection of renowned colleges based on criteria such as specialization, field of interest, fees, and location, among others.
                  </p>
                  <p className="mt-3">
                    Furthermore, it simplifies the application process by eliminating the need for students to repeatedly fill in their personal details for each college application. If a student encounters any difficulties while using the Student Career Reach portal, our experienced school admission consultants will be readily available to provide assistance, making the process smooth and easy.
                  </p>
                </span>
              )}
              <button onClick={handleToggle} className="mt-1 px-5 py-2 why-btn1">
                {showMore ? '-Show less' : '+Show more'}
              </button>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 mt-1">
                  <img src="admission.jpg" alt="" className="about-image1" />
                </div>
                <div className="col-md-6 mt-1">
                  <img src="admissionabout.jpg" alt="" className="about-image1" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-1">
                  <img src="admissionabout.jpg" alt="" className="about-image1" />
                </div>
                <div className="col-md-6 mt-1">
                  <img src="admission.jpg" alt="" className="about-image1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Numberbox />
      <Services />
      <Vision />
    </div>
  );
};

export default About;
