import React from 'react';
import { Link } from "react-router-dom";
import "./Reason.css";
const Reason = () => {
  return <div>
   <div className='container-fluid mt-5 reason-color p-5'>
    <div className='container'>
<div className='row'>
    <div className='col-md-1'></div>
    <div className='col-md-6'>
<h2 className='reason-text text-white'>Reason to Hire an Expert admission consultant </h2>
<p  className='mt-3 text-white reason-p'>Hiring an expert admission consultant ensures personalized guidance tailored to your academic and career goals. They offer in-depth knowledge of admission processes, helping navigate complex requirements and deadlines efficiently. Consultants provide valuable insights into college choices, ensuring optimal fit based on your strengths and aspirations. Their expertise minimizes stress, enhances application quality, and maximizes chances of securing admissions and scholarships. Overall, they streamline the entire process, empowering you to make informed decisions and achieve your educational aspirations.
</p>
    </div>
    <div className='col-md-5'>
    <Link to="/contact" className="  px-3 py-2 bgreason" >
    Apply Now <i class="bi bi-arrow-right"></i>
  </Link>
    </div>

</div>
    </div>

   </div>
        </div>;
};

export default Reason;