import React from 'react';
import './Distance.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faChalkboardTeacher,faVideo,faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
const Distance = () => {
  return (
    <div>
      <div className="container-fluid distance-image">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-4">
              <center><h2 className="text-white distance-smt">Distance Learning</h2></center>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5 distancecolor p-4">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mt-4 distance1 p-2">
              <img src="admission.jpg" alt="" className="distance-image1" />
            </div>
            <div className='col-md-7 mt-2 p-2'>
              <h2 className='why-text ms-3'>Distance <span className='why-span'>Learning</span></h2>
              <p className='distance-p mt-2 ms-3'>
                One of the most appealing aspects of distance learning is its flexibility, allowing students to learn at any time and from any location. During the COVID-19 pandemic, the necessity for social distancing highlighted the significance of <span className='why-p1'>distance learning institutions in India.</span> To maintain the quality of education and prevent any disruptions, the education sector has embraced digital solutions. This shift has made education more accessible and convenient for learners of all ages.
              </p>
              <p className='distance-p ms-3'>
                Distance learning is ideal for those who wish to continue their education while working. Many people face significant challenges in obtaining a degree, and the enrollment process can also be cumbersome. Individuals who marry young, encounter financial difficulties, or need to balance family and job responsibilities often struggle to complete their studies. Today, the Internet has helped bridge the gap between proximity and distance, making education more accessible for everyone.
              </p>
              <p className='distance-p ms-3'>
                Moreover, e-learning utilizes visual media, which enhances long-term retention of the material. Online education offers certifications from prestigious international institutions, often at a fraction of the cost of traditional classroom settings. This approach provides a global educational experience that broadens perspectives in the corporate world. Additionally, many top-tier <span className='why-p1'>distance learning MBA programs in India are available,</span> allowing individuals to advance their careers from the comfort of their homes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid mt-5'>
        <div className='container'>
          <h2 className='text-center why-text'>Benefits of <span className='why-span'>Distance Learning</span></h2>
          <div className='row mt-5 p-2'>
            <div className='col-md-3 distance-col p-5   mt-2 '>
              <h1 className='text-center'><FontAwesomeIcon icon={faChalkboardTeacher} color="#00375D" size="2x" /></h1>
           <h3 className='text-center mt-3 distance-icon'>World Class Professors</h3>
           <p className='text=center lead distance-icon1'>Individuals can receive training and instruction from renowned professors worldwide who are experts in their fields.</p>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-3 distance-col p-5 mt-2 ms-2'>
              <h1 className='text-center'><FontAwesomeIcon icon={faVideo}  color="#00375D" size="2x" /></h1>
           <h3 className='text-center mt-3 distance-icon'>Record The Lectures</h3>
           <p className='text=center lead distance-icon1'>Students can record lessons, translate them into their native language, and study at their own pace to gain  better understanding of the topics.</p>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-3 distance-col p-5 ms-2 mt-2'>
              <h1 className='text-center'><FontAwesomeIcon icon={faBookOpen}  color="#00375D" size="2x" /></h1>
           <h3 className='text-center mt-3 distance-icon'>Flexible Learning</h3>
           <p className='text=center lead distance-icon1'>You can enroll at any time and access online learning resources from anywhere, at any time, to acquire valuable skills and make significant changes in your life.</p>
            </div>
            <div className='col-md-1'></div>
           
          </div>
        </div>
      </div>
      <div className='container-fluid distancecolor p-5 mt-5'>
<div className='container'>
<div className='row'>
<h2 className='text-center why-text'>Education for  <span className='why-span'>All</span></h2>
<p className='text-center distance-p1 mb-4'>
Distance learning encompasses a wide range of options, including open schedule courses, fixed-time courses, computer-based courses, hybrid learning, as well as asynchronous and synchronous learning. The choice depends entirely on what you wish to pursue. This mode of education is highly flexible, easily accessible, and cost-effective. With distance learning, "education for all" becomes a reality!

</p>

</div>
<Link to="/contact" className=" px-5 py-2 distance-btn" >
    Apply Now 
  </Link>
</div>
</div>
    </div>
  );
};

export default Distance;
