import React from 'react';
import { Link } from "react-router-dom";
import "./Rupecard.css";
const Rupecard = () => {
  return <div>
<div className='container-fluid  color'>
<div className=' mt-5 mb-5'>
<div className='row'>
  <div className='col-md-1'></div>
<div className='col-md-3 rupe-col'>
<h4 className='text-center rupe1'>Degree Courses</h4>
<span className="pipe2"></span> {/* The pipe is now inline */}
<h6 className='rupe-text'>
Consultation services for careers and courses in Karnataka, covering streams such as Sciences, Arts, Computer Applications, Law, Allied Health Sciences, Nursing, Commerce, and Management.

</h6>
<p className='rupe-p'>
<i class="bi bi-check-lg i"></i>Course Consultation
</p>
<p className='rupe-p'>
<i class="bi bi-check-lg i"></i>College Guidance
</p>
<p className='rupe-p'>
<i class="bi bi-check-lg i"></i>Counseling Support
</p>
<p className='rupe-p11'>
<i class="bi bi-x-lg i"></i>Admission Help
</p>
<p className='rupe-p11'>
<i class="bi bi-x-lg i"></i>College Visits
</p>
<p className='rupe-p11'>
<i class="bi bi-x-lg i"></i>Housing Arrangements
</p>
<Link to="/contact" className=" mb-5 px-4 py-2 service1-b " >
    READ MORE 
  </Link>

</div>
<div className='col-md-3 rupe1-col'>
<h4 className='text-center rupe1'>Professional Courses</h4>
<span className="pipe3"></span> {/* The pipe is now inline */}
<h6 className='rupe-text'>
Consultation services for careers and courses in Karnataka, covering streams such as Sciences, Arts, Computer Applications, Law, Allied Health Sciences, Nursing, Commerce, and Management.

</h6>
<p className='rupe-p'>
<i class="bi bi-check-lg i"></i>Course Consultation
</p>
<p className='rupe-p'>
<i class="bi bi-check-lg i"></i>College Guidance
</p>
<p className='rupe-p'>
<i class="bi bi-check-lg i"></i>Counseling Support
</p>
<p className='rupe-p1'>
<i class="bi bi-x-lg i"></i>Admission Help
</p>
<p className='rupe-p1'>
<i class="bi bi-x-lg i"></i>College Visits
</p>
<p className='rupe-p1'>
<i class="bi bi-x-lg i"></i>Housing Arrangements
</p>
<Link to="/contact" className=" mb-5 px-4 py-2 service1-bb " >
    READ MORE 
  </Link>
</div>
<div className='col-md-3 rupe2-col'>
<h4 className='text-center rupe1'>Medical Courses
</h4>
<span className="pipe2"></span> {/* The pipe is now inline */}
<h6 className='rupe-text'>
Consultation services for careers and courses in Karnataka, covering streams such as Sciences, Arts, Computer Applications, Law, Allied Health Sciences, Nursing, Commerce, and Management.

</h6>
<p className='rupe-p'>
<i class="bi bi-check-lg i"></i>Course Consultation
</p>
<p className='rupe-p'>
<i class="bi bi-check-lg i"></i>College Guidance
</p>
<p className='rupe-p'>
<i class="bi bi-check-lg i"></i>Counseling Support
</p>
<p className='rupe-p111'>
<i class="bi bi-x-lg i"></i>Admission Help
</p>
<p className='rupe-p111'>
<i class="bi bi-x-lg i"></i>College Visits
</p>
<p className='rupe-p111'>
<i class="bi bi-x-lg i"></i>Housing Arrangements
</p>
<Link to="/contact" className=" mb-5 px-4 py-2 service1-b " >
    READ MORE 
  </Link>
</div>
</div>
</div>
</div>
  </div>
  
};
export default Rupecard;