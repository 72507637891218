import React from 'react';
import { Link } from "react-router-dom";
import "./Whychose.css";
const Whychose = () => {
  return <div>
     <div className="container-fluid mt-5">
<div className='container mt-5'>
<div className='row mt-5'>
  <div className='col-md-8 '>
    <p className='whychose-p'>
    Unlock your potential with expert guidance on your educational path. Discover new opportunities with Student Career Coach – where your future starts!

    </p>
    <h5 className='whychose-h'>We provide admission assistance, college tours, and accommodation arrangements. Contact us today to learn more!
</h5>
  </div>
  <div className='col-md-1  '>
      <h1>
        <span className="pipe-why"></span>
      </h1>
    </div>
    <div className='col-md-3 '>
    <Link to="/contact" className=" mb-5  whychoseb1tn  " >
   ENQUIRY NOW
  </Link>
    </div>
   

</div>
</div>
      </div>
      <div className="container-fluid mt-5 why-color">
<div className='container mt-5'>
<div className='row mt-5'>
  <div className='col-md-4 '>
    
    <h2 className='s-1'>Why Professional Consultations
   
        
        </h2>
  </div>
  <div className='col-md-1 '>
      <h1>
        <span className="pipe1"></span>
      </h1>
    </div>
    <div className='col-md-6'>
<p className="s-2 why-1">
Choosing the right career, course, and college can be overwhelming. Professional consultation provides structured guidance to help you make decisions aligned with your goals and strengths. Our consultants stay updated on trends, offering accurate and relevant advice.
</p>
    </div>
   

</div>
</div>
      </div>
</div>
  };

  export default Whychose;