import React from 'react';
import "./Coursescard.css";
import { Link } from "react-router-dom";
const Coursescard = () => {
  return (
    <div>
 <div class="container mt-5">
  <div class="row">
     <p class="lead text-center course1">EXPLORE</p>
        <h1 class="text-center course1-heading">THE COURSES</h1>
         <p class="text-center  lead1">A successful career is built by aligning courses that match one’s passion with the skills<br/> demanded by employers. What are the in-demand courses for this?</p>
      
    <div class="col-md-4">
      
      <div class="card mb-4" >
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
 <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="medical.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="medical1.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="medical2.jpg" class="d-block w-100" alt="..."/>
    </div>
  </div>

</div>
  <div class="card-body ">
   <h4  class="mt-2 text-center coursecard-h">MEDICINE</h4>
        <p class="text-center coursecard-h-p ">M.B.B.S, B.D.S, Allied Health or Nursing. </p>

        <Link to="/contact" className=" mb-5 px-4 py-2 coursecard-b " >
    READ MORE 
  </Link>
  </div>
</div>
       
    </div>
    <div class="col-md-4">
      
      <div class="card mb-4" >
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="pharma.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="pharma.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="pharma.jpg" class="d-block w-100" alt="..."/>
    </div>
  </div>

</div>
  <div class="card-body ">
   <h4  class="mt-2 text-center coursecard-h">Pharmacy</h4>
        <p class="text-center coursecard-h-p">B. Pharm, Pharm D. or M. Pharm.

        </p>

        <Link to="/contact" className=" mb-5 px-4 py-2 coursecard-b " >
    READ MORE 
  </Link>
  </div>
</div>
       
    </div>
    <div class="col-md-4">
      
      <div class="card" >
<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
   <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="engineer.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="engineer1.jpg" class="d-block w-100" alt="..."/>
    </div>
    <div class="carousel-item">
      <img src="engineer2.jpg" class="d-block w-100" alt="..."/>
    </div>
  </div>

</div>
  <div class="card-body ">
   <h4  class="mt-2 text-center coursecard-h">Engineering</h4>
        <p class="text-center coursecard-h-p">Computer Science, AI & ML, Info Science.
           </p>

           <Link to="/contact" className=" mb-5 px-4 py-2 coursecard-b " >
    READ MORE 
  </Link>
  </div>
</div>
       
    </div>
    
  </div>
   
 </div>
 </div>
     );
    };
    
export default Coursescard;
    