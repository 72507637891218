import React from 'react';
import "./sciencecourse.css";
import { Link } from "react-router-dom";
const sciencecourse = () => {
  return (
    <div>
       <div class="container mt-5">
  <div class="row">
    
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="engineer1.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 "> Engineering</h4>
        <p class=" coursecard-h-p11 ">Polytechnic, B. Tech, M. Tech </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>  Engineering starts with a 4-year B.Tech/B.E.. Next, a 2-year M.Tech/M.E. allows specialization. Advanced research is pursued through a 3 to 5-year Ph.D.
        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="pharma1.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Pharmacy </h4>
        <p class=" coursecard-h-p11 ">D. Pharm, B. Pharm, Pharm D., M. Pharm. </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>Pharmacy education includes a 2-year D. Pharm, a 4-year B. Pharm, a 6-year Pharm D (with internship), and a 2-year M. Pharm.

        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="nursing.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Nursing , Paramedical & Allied Science </h4>
        <p class=" coursecard-h-p11 ">Nursing ,Bmlt,Gnm,Anm. </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>Nursing education includes a 3-year GNM, a 2-year ANM, a 4-year B.Sc. Nursing, and a 3-year BMLT.

        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="artiche.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Architecture</h4>
        <p class=" coursecard-h-p11 ">B. Arch, M. Arch.</p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>The B.Arch. (Bachelor of Architecture) typically takes 5 years, while the M.Arch. (Master of Architecture) usually requires 2 years of study.

        </p>    
  </div>
</div>
       
    </div>
    
  
    
  </div>
  <div class="row">
    
  <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="agri.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Agriculture </h4>
        <p class=" coursecard-h-p11 ">B.Sc, M.Sc [Horticulture,Forestry,Agriculture] </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>The B.Sc. in Horticulture, Forestry, and Agriculture typically takes 3 years to complete. The M.Sc. in these fields usually requires an additional 2 years.

        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="yoga.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Yogic Science & Naturopathy</h4>
        <p class=" coursecard-h-p11 ">Bnys, B.Sc, M.Sc, M.A, Ph. D,PGD[Yoga] </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>The BNYS takes 5.5 years, B.Sc. and M.Sc. are 3 and 2 years, respectively. The M.A. is 2 years, Ph.D. spans 3 to 5 years, and the PGD in Yoga is 1 year.

        </p>    
  </div>
</div>
       
    </div>
   
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="engineer.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Science and Technology</h4>
        <p class=" coursecard-h-p11 ">B.S.C, M.S.C(pcm/pcb/cbz/cs) </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span> Science education includes a 3-year B.Sc degree followed by a 2-year M.Sc program with specializations such as PCM, PCB, CBZ, or CS, offering diverse opportunities.
        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="therapy.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Physiotherapy</h4>
        <p class=" coursecard-h-p11 ">B.P.T, M.P.T.</p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span> B.P.T. is a 4.5-year program, including a 6-month internship.M.P.T. is a 2-year postgraduate course focusing on advanced techniques and specialization.
        </p>    
  </div>
</div>
       
    </div>
   
   
    
  
    
  </div>
 </div>
    
    </div>
  );
};

export default sciencecourse;
