import React from 'react';
import { Link } from "react-router-dom";
import "./Services.css";
const Services = () => {
  return <div>
<div className='container-fluid servicescolor p-5'>
<div className='container'>
<div className='row'>
<h2 className='text-center services-h1'>OUR SERVICES</h2>
<p className='text-center services-p'>At Student Career Coach, we offer comprehensive guidance in school selection, application preparation, scholarship assistance, test preparation, and interview coaching to ensure your academic success.

</p>
</div>
</div>
</div>
  </div>
  };

  export default Services;