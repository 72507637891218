import React from 'react';
import "./sciencecourse.css";
import { Link } from "react-router-dom";
const Commerce = () => {
  return (
    <div>
       <div class="container mt-5">
  <div class="row">
    
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="commerce.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Computer Applications</h4>
        <p class=" coursecard-h-p11 ">B.C.A, M.C.A, B.Sc (CS), M.Sc (CS) </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span> The B.C.A. and B.Sc. (CS) each take 3 years, while the M.C.A. and M.Sc. (CS) are 2 years.
        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="commerce1.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Business Management </h4>
        <p class=" coursecard-h-p11 ">B.B.A, B.B.M, P.G.D.M, MBA. </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>The B.B.A. and B.B.M. programs each typically take 3 years, while the P.G.D.M. and MBA usually require 2 years.

        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="commerce3.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Commerce & Economics</h4>
        <p class=" coursecard-h-p11 ">B.Com, M.Com, Accountancy, CA</p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>B.Com. is 3 years, M.Com. is 2 years. CA and CS take 3 to 5 years, with specialized Accountancy studies.

        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="hotel.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Hotel Management</h4>
        <p class=" coursecard-h-p11 ">Hotel Management[Diploma/Bachelor] </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>A Diploma in Hotel Management lasts 1-2 years, while a Bachelor's degree spans 3-4 years.

        </p>    
  </div>
</div>
       
    </div>
    
    
  
    
  </div>
  
 </div>
    
    </div>
  );
};

export default Commerce;
