import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './whyshala.css';

const Whyshala = () => {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => {
    setShowMore(!showMore);
  };

  return (
    <div className='container-fluid mt-5'>
      <div className='container'>
        <div className='row'>
          <h2 className='text-center why-text'>Why <span className='why-span'>Student Career Coach?</span></h2>
          <div className='col-md-1'></div>
          <div className='col-md-10'>
            <p className='why-p mt-3'>
            "It's not just about how hard you work, but how smartly you manage your time, resources, mindset, and efforts to achieve better results." - Anonymous
<br/>
            <p className='why-p mt-3'> 
            In the present era, time is money, and to value your money and efforts,<span className='why-p1'> we introduce Student Career Coach,</span> an admission consultant. We are an exceptionally selective digital platform designed specifically for aspiring students to provide counseling, guidance, and assistance in career mapping.
            </p>
              {showMore && (
                <span className='mt-3' >
                  
                  Navigating career choices can pose challenges for both parents and students. Without access to accurate information, making the right decision becomes difficult. We aim to address all queries related to admissions and simplify the educational journey through the following services:
                  <br/>
                <p className='mt-3'> Student Career Coach adopts a student-centered approach. We understand students’ profiles and expectations, and then conduct a well-structured process of vocational guidance, counseling, and career mapping.
                  </p>
<p className='mt-3'>
Student Career Coach also maintains a strong network with over 10,000 alumni, enabling us to facilitate your access to excellent summer internships and final placement opportunities with prestigious BSE, NSE listed companies in the corporate world.
</p>
<p className='mt-3'>
<span className='why-p1'>In conclusion, Student Career Coach </span> holds a prominent position among admission consultants in India, serving as the premier portal for addressing a wide range of admission and placement-related queries in a highly personalized manner. We keep you informed about the latest developments regarding institutes, various scholarship programs, and student development initiatives. Our certified college admissions consultants provide clear and accurate college comparisons, enhancing the human experience in digital interaction.
</p>
                  </span>
              )}
            </p>
            <button onClick={handleToggle} className="mt-1 px-5 py-2 why-btn" >
              {showMore ? '-Show less' : '+Show more'}
            </button>
          </div>
          <div className='col-md-1'></div>
        </div>
      </div>
    </div>
  );
};

export default Whyshala;
