import React from 'react';
import { Link } from "react-router-dom";
import "./Advantage.css";
const Advantage = () => {
  return <div>
    <div className='container-fluid mt-5 advantageimage'>
        <div className='container'>
            <div className='row'>
            <h2 class="text-center  mt-5 advantage-h"  >Advantages of Student Career Coach</h2>
            <p class="advantage-p text-center">
            Below are the reasons to choose Student Career Coach
            </p>
            <div class="col-md-4 mb-5 mt-5">

<div class=" advantage-card p-3 ">
    <h1 class="text-black mt-4 ms-4 advantage-h1" >1</h1>  
    <div class="card-body animated flash infinite ">
        <h2 class="card-title text-black ms-4 bg6 mt-3 "  >Explore Options</h2>
        <p class=" advantage-p1 text-black ms-4 mt-3">We help you explore institute options. Our counselors guide you to discover all your choices in one place.</p>
          
          <Link to="/about" className=" ms-4 px-4 py-2 mt-2 advantage-btn" >
    Read More </Link>
          </div>
      </div>
    </div>
    <div class="col-md-4 mb-5 mt-5">
 <div class="  advantagecard1 p-3" >
          <h1 class=" mt-4 ms-4 advantage-h11" >2</h1>  
          <div class="card-body animated flash infinite  ">
              <h2 class=" mt-3  ms-4 advantagetitle  bg6 ">Easy Research</h2>
              <p class=" ms-4 text-white ms-4 mt-3 advantage-p1">Our tool simplifies your research by comparing and narrowing down colleges based on your preferences.

              </p>
              <Link to="/about" className=" ms-4 px-4 py-2 mt-2 advantage-btn1" >
              Read More </Link>
                </div>
            </div>
          </div>
          <div class="col-md-4 mb-5 mt-5">
 <div class="  advantage-card2 p-3" >
          <h1 class=" mt-4 ms-4 advantage-h11 text-white" >3</h1>  
          <div class="card-body animated flash infinite  ">
              <h2 class=" mt-3  ms-4 text-white  bg6  ">Professional Assistance</h2>
              <p class=" ms-4 text-white ms-4 mt-3 advantage-p1">Student Career Coach guides you through form filling, eligibility, college selection, fees, and loan support.

              </p>
              <Link to="/about" className=" ms-4 px-4 py-2 mt-2 advantage-btn2" >
              Read More </Link>
                </div>
            </div>
          </div>

            </div>

        </div>

    </div>
        </div>;
};

export default Advantage;