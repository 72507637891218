import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './vision.css';

const Vision = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <div className="container-fluid mt-3 mb-3 p-5 visioncolor">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
            <h2 className=' why-text '>Our <span className='why-span'>Vision</span></h2>
              <p className="lead"><i className="bi bi-check2"></i>Streamline the college search and application process efficiently.</p>
              <hr />
              <p className="lead"><i className="bi bi-check2"></i>Save students' time, money, and effort in college applications.</p>
              <hr />
              <p className="lead"><i className="bi bi-check2"></i> Provide a hassle-free experience for higher education seekers.</p>
              <hr />
              <p className="lead"><i className="bi bi-check2"></i>Offer a seamless, user-friendly one-stop admission solution.</p>
              <hr />
              <p className="lead"><i className="bi bi-check2"></i>Simplify the journey to top colleges with ease.</p>
              <hr />
            </div>
            <div className="col-md-6" data-aos="fade-left">
              <img src="admissionabout.jpg" alt="" className="vision-image" />
            </div>
          </div>
          <div className="row mt-5">
          <div className="col-md-6 mt-3" data-aos="fade-right">
              <img src="admissionabout.jpg" alt="" className="vision-image" />
            </div>
            <div className="col-md-6 mt-3">
            <h2 className=' why-text '>Our <span className='why-span'>Mision</span></h2>
              <p className="lead"><i className="bi bi-check2"></i>Create opportunities for every student to succeed in education.</p>
              <hr />
              <p className="lead"><i className="bi bi-check2"></i>Ensure constitutional right to education, regardless of location. </p>
              <hr />
              <p className="lead"><i className="bi bi-check2"></i> Bridge financial gaps for students pursuing higher education.</p>
              <hr />
              <p className="lead"><i className="bi bi-check2"></i> Promote inclusivity across all backgrounds.</p>
              <hr />
              <p className="lead"><i className="bi bi-check2"></i> Uphold equal access to education for every student.</p>
              <hr />
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
