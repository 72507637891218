import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Home.css";
import Whyshala from "../Whyadmissionshala/Whyshala";
import Developmentp from "../Developmentprogram/Developmentp";
import Reason from "../Reason/Reason";

import Advantage from "../Advantageshala/Advantage";
import Wepromise from "../Wepromise/Wepromise";
import Services from "../Services/Services";
import Enquiryform from "../Enquiry/Enquiryform";
import Testimonial from "../Testimonial/Testimonial";
import Coursescard from "../Courses/Coursescard";
import Testi from "../Testi";


function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="container-fluid homeimage">
        <div className="container">
         <div className="row">
            <div className="col-md-12">
            <h2 className=" home-text">
            Strive, Succeed, and Excel 
            <br /> with Student Career Coach</h2>
          <Link to="/contact" className=" mt-3 px-4 py-2 bghome" >
    Read More <i class="bi bi-arrow-right"></i>
  </Link>
            </div>

         </div>
        </div>
       
      </div>
      <Enquiryform/>
      <Whyshala/>
      <Reason/>
      <Developmentp/>
      <Coursescard/>
      <Advantage/>
    <Wepromise/>
    <Services/>
    
  
<Testi/>
    </div>
  );
}

export default Home;
