import React from 'react';
import "./Wepromise.css";
const Wepromise = () => {
  return <div>
    <div className='container-fluid p-5 promisecolor'>
<div className='container'>
<h2 className='text-center promise-text'>What We <span className='promise-span'> Promise?</span></h2>
  <div className='row mt-5'>
  <div className='col-md-1'></div>
  <div className='col-md-3 me-4 '>
    <h1 className='text-center promise-h1'><i class="bi bi-bar-chart-line promise-bg"></i></h1>
    <h2 className='text-center mt-4 promise-h2'>100% Transparency</h2>
    <p className=' text-center mt-4 promise-p'>We offer complete transparency and deliver the best.</p>
  </div>
  <div className='col-md-3 wp1 wp111 '>
    <h1 className='text-center promise-h1'><i class="bi bi-pencil-fill promise-bg"></i></h1>
    <h2 className='text-center mt-4 promise-h2'>Free Counselling</h2>
    <p className=' text-center mt-4 promise-p'>Our Counsellors provide free counseling about all aspects.</p>
  </div>
  <div className='col-md-3 wp'>
    <h1 className='text-center promise-h1'><i class="bi bi-wallet2 promise-bg"></i></h1>
    <h2 className='text-center mt-4 promise-h2'>Pay Directly</h2>
    <p className=' text-center mt-4 promise-p'>Pay the Tuition Fee and other charges directly to the college.</p>
  </div>
  </div>
  <div className='row mt-5'>
  <div className='col-md-1'></div>
  <div className='col-md-3 me-4 '>
    <h1 className='text-center promise-h1'><i class="bi bi-credit-card promise-bg"></i></h1>
    <h2 className='text-center mt-4 promise-h2'>No Advance Payement</h2>
    <p className=' text-center mt-4 promise-p'>We do not charge any advance payment for admission.</p>
  </div>
  <div className='col-md-3 wp1'>
    <h1 className='text-center promise-h1'><i class="bi bi-person-plus-fill promise-bg"></i></h1>
    <h2 className='text-center mt-4 promise-h2'>100 % Assistance</h2>
    <p className=' text-center mt-4 promise-p'>We offer maximum support till admission is completed.</p>
  </div>
  <div className='col-md-3 wp'>
    <h1 className='text-center promise-h1'><i class="bi bi-card-list promise-bg"></i></h1>
    <h2 className='text-center mt-4 promise-h2'>College Visit</h2>
    <p className=' text-center mt-4 promise-p'>We arrange a college visit before taking admission.</p>
  </div>
  </div>
  <div className='row mt-3'>
    <div className='col-md-12 p-5 promise-help'>
      <h2 className='promise-bg2'>How Can We Help You?</h2>
      <p className='mt-4 promise-bg3'>At Student Career Coach, we're dedicated to guiding you through every step of your educational journey. Whether you're exploring schools, preparing applications, or seeking scholarships, our expert advice and resources are here to support you. Our personalized services are designed to address your unique needs and aspirations. Let us help you turn your academic dreams into reality. From test preparation to interview coaching, we offer comprehensive assistance to ensure your success. Join us and take the first step towards a brighter future.

</p>

    </div>

  </div>

</div>
    </div>
        </div>;
};

export default Wepromise;