import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';  // Ensure this path is correct
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Provider } from 'react-redux';
import { store } from './Component/store';  // Correct path to your store
import ChatBox from './Component/ChatBox';  // Correct path to your ChatBox
import reportWebVitals from './reportWebVitals';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


// Get the root element
const rootElement = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(rootElement);

// Render the App component, which will include ChatBox if needed
root.render(
  <Provider store={store}>
    <App />  {/* Render the App component */}
   
  </Provider>
);

reportWebVitals();
