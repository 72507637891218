import React from 'react';
import './Contact.css';
import Contactcard from './Contactcard';

const Contact = () => {
  return (
    <div>
      <div className="container-fluid contact-image ">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-4">
            <center><h2 className="text-white  contactsmt" >Contact Us <br/> <span className='contact-span'>We are eager to hear from you.
</span></h2></center>
            </div>
          </div>
        </div>
      </div>
     <Contactcard/>
      <div className='container-fluid contactcolor mt-5'>
<div className='container py-3 px-3'>
<div className='row'>
  <div className='col-md-6'>
  <h2 class="mt-5 contact-h1">Get In Touch With Us</h2>
  <p class="text-dark  mt-4">Lorem ipsum dolor sit amet consectetur adipiscing elit  consectetur Lorem ipsum dolor  consectetur adipiscing elit  consectetur consectetur Lorem ipsum dolor  consectetur adipiscing elit  consectetur.</p>
  <h4 class="p-2 contact-h2"><i class="bi bi-geo-alt-fill p-2 contact-h3" ></i><span class="ms-2">Our Location</span></h4>
  <p class="text-dark ms-5">Chandigarh India</p>
   <h4 class="p-2 contact-h2" ><i class="bi bi-telephone-outbound p-2 contact-h3"></i><span class="ms-2">Phone Number</span></h4>
    <p class="text-dark ms-5 ">+917017388084</p>
    <h4 class="p-2 contact-h2" ><i class="bi bi-envelope-fill p-2 contact-h3" ></i><span class="ms-2">Email Address</span></h4>
    <p class="text-dark ms-5 ">studentcareer24@gmail.com</p>

  </div>
  <div className='col-md-6 contactcolor1 p-3'>
  <h2 class="mt-4  ms-3 contact-h4">Contact Us</h2>
  <div className=" pe-3 ps-3 ">
  <label for="name" className="form-label text-black contactbg">Full Name</label>
  <input type="text"  className="form-control enquiry-form p-2  "  placeholder='Full Name' />
  </div>
  <div className=" pe-3 ps-3 mt-2 ">
  <label for="name" className="form-label text-black contactbg">Phone Number</label>
  <input type="text"  className="form-control enquiry-form p-2  "  placeholder='Phone Number' />
  </div>
  <div className=" pe-3 ps-3 mt-2 ">
  <label for="name" className="form-label text-black contactbg">Email id</label>
  <input type="text"  className="form-control enquiry-form p-2  "  placeholder='Email id' />
  </div>
  <div className=" pe-3 ps-3 mt-2 ">
  <label for="name" className="form-label text-black contactbg">City</label>
  <input type="text"  className="form-control enquiry-form p-2  "  placeholder='City' />
  </div>
  <div className=" pe-3 ps-3 mt-2 ">
  <label for="name" className="form-label text-black contactbg">State</label>
  <input type="text"  className="form-control enquiry-form p-2  "  placeholder='State' />
  </div>
  <div className=" pe-3 ps-3 mt-2 ">
  <label for="name" className="form-label text-black contactbg">Message</label>
  <input type="text"  className="form-control enquiry-form p-2  "  placeholder='Message' />
  </div>
 
  <button   className=" mt-4 contact-btn  ms-3 px-5 py-2" >Submit</button>
  </div>

</div>
</div>
      </div>
          
           
           
          </div>
       
  );
};

export default Contact ;
