import React from 'react';
import './contactcard.css';

const contactcard = () => {
 return (
    <div>
      <div className='container-fluid mt-5'>
<div className='container'>
<div className='row'>
<div class="col-md-4 ">
                <div class="    mt-3 p-4 contact-card" >
                  <h1 class="text-center "><i class="bi bi-geo-alt-fill contact-card1"> </i></h1>
                   <h5 class="text-center">Our main office</h5>
                    <p class="text-center lead">Chandigarh India</p>

                </div>
                
              </div>
              <div class="col-md-4 ">
                 <div class="   mt-3 p-4 text-center contact-card" >
              <h1 class="text-center "><i class="bi bi-telephone-outbound-fill contact-card1"></i></h1>
               <h5 class="text-center ">Phone Number</h5>
                    <p class="text-center lead">+917017388084</p>
                
              </div>
            </div>
            <div class="col-md-4 ">
                <div class="   mt-3 p-4 text-center contact-card" >
              <h1 class="text-center "><i class="bi bi-envelope contact-card1"></i></h1>
                  <h5 class="text-center">Email</h5>
                    <u><p class="text-center lead">studentcareer24@gmail.com</p></u>
                  </div>
                
              </div>
</div>
</div>
      </div>
    </div>
  );
};

export default contactcard;
