import React from 'react';

import "./Enquiry.css";
const Enquiryform = () => {
  return <div>
  <div className='container-fluid enquiry'>
    <div className='container'>
        <div className='row'>
<div className='col-md-2'></div>
<div className='col-md-8  promise-help p-5'>
<center><h2 className='mb-4 enquiry-h2'>Enquiry <span className='enquiry-span'> Form </span></h2></center>
    <div className='row'>
<div className='col-md-6'>
<div className="mb-3 ">
    <input type="text"  className="form-control enquiry-form p-2  "  placeholder='First Name' /> 
    </div>
</div>
<div className='col-md-6'>
<div className="mb-3">
    <input type="text"  className="form-control enquiry-form p-2 "  placeholder='Last Name' /> 
    </div>
</div>
    </div>
    <div className='row'>
<div className='col-md-6'>
<div className="mb-3 ">
    <input type="number"  className="form-control enquiry-form p-2 "  placeholder='Phone number' /> 
    </div>
</div>
<div className='col-md-6'>
<div className="mb-3">
    <input type="text"  className="form-control enquiry-form p-2 "  placeholder='Email id' /> 
    </div>
</div>
    </div>
    <div className='row'>
<div className='col-md-6'>
<div className="mb-3 ">
    <input type="text"  className="form-control enquiry-form p-2 "  placeholder='State' /> 
    </div>
</div>
<div className='col-md-6'>
<div className="mb-3">
    <input type="text"  className="form-control enquiry-form p-2 "  placeholder='City' /> 
    </div>
</div>
    </div>
    <div className='row'>
<div className='col-md-6'>
<div className="mb-3 ">
    <input type="text"  className="form-control enquiry-form p-2 "  placeholder='Preferred city' /> 
    </div>
</div>
<div className='col-md-6'>
<div className="mb-3">
    <input type="text"  className="form-control enquiry-form p-2 "  placeholder='Stream' /> 
    </div>
</div>
    </div>
    <center> <button   className=" mt-4 enquiry-btn" >SUBMIT</button></center>
                           
</div>
<div className='col-md-2'></div>
        </div>

    </div>

  </div>
 
        </div>
};

export default Enquiryform;