

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './Whatsapp.css';  // CSS फ़ाइल आयात करें

const Whatsapp = () => {
    const [isOpen, setIsOpen] = useState(false);  // मेनू को टॉगल करने के लिए स्टेट

    const handleWhatsAppClick = (phoneNumber, message) => {
        const whatsappUrl = `https://wa.me/${+918958851281}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };
    const handleWhatsAppClick1 = (phoneNumber, message) => {
        const whatsappUrl = `https://wa.me/${+917017388084}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className="whatsapp-container">
           
            {isOpen && (
                <div className="whatsapp-menu">
                 <h3 >   <i class="bi bi-whatsapp ii"></i> Start a Conversation</h3>
                    <p className="w-p">
                    Hi! Click one of our member below to chat on WhatsApp
                    </p>
                    <div className="whatsapp-options">
                        <button 
                            className="whatsapp-option-btn" 
                            onClick={() => handleWhatsAppClick('+918958851281', 'Hello, I want to talk to HR')}>
                            Talk to HR
                        </button>
                        <button 
                            className="whatsapp-option-btn" 
                            onClick={() => handleWhatsAppClick1('+917017388084', 'Hello, I want to talk to the Head Office')}>
                            Head Office
                        </button>
                    </div>
                    {/* क्रॉस आइकन */}
                    <div className="whatsapp-close" onClick={() => setIsOpen(false)}>
                        <FontAwesomeIcon icon={faTimes} className="close-icon" />
                    </div>
                </div>
            )}

            {/* व्हाट्सएप आइकन */}
            {!isOpen && (
                <div className="whatsapp-float" onClick={() => setIsOpen(true)}>
                    <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
                </div>
            )}
        </div>
    );
};

export default Whatsapp;
