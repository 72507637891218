import React from 'react';
import { Link } from "react-router-dom";
import "./Services.css";
import Rupecard from './Rupecard';
import Whychose from './Whychose';

const Servicestudent = () => {
  return <div>
  <div className="container-fluid service-image mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <center>
                <h2 className="text-white service-span">
                  <span>Services</span>
                </h2>
                <p className='servicesmt text-white'>
                Advisory Services, Guidance & Enrollment Assistance
                </p>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5">
<div className='container mt-5'>
<div className='row mt-5'>
  <div className='col-md-4 '>
    
    <h2 className='s-1'>Professional Consultations
   
        
        </h2>
  </div>
  <div className='col-md-1 '>
      <h1>
        <span className="pipe1"></span>
      </h1>
    </div>
    <div className='col-md-6'>
<p className="s-2">
Embarking on your educational journey is a major milestone, and at Student Career Coach, we're committed to being your trusted guide through the complex world of careers, courses, and colleges. Whether you're a recent graduate or considering a new direction in your studies, our expert consultants are here to support you.
</p>
    </div>
   

</div>
</div>
      </div>
      <Rupecard/>
      <Whychose/>
  </div>
  };

  export default Servicestudent;