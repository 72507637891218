import React from 'react';
import "./Courses.css";
import { Link } from "react-router-dom";
import Sciencecourse from './sciencecourse';
import Commerce from './Commerce';
import Arts from './Arts';

const Courses = () => {
  return (
    <div>
      <div className="container-fluid course-image mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <center>
                <h2 className="text-white course-span">
                  <span>Courses</span>
                </h2>
                <p className='coursesmt text-white'>
                  After 12th or Graduation
                </p>
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid mt-5'>
        <div className='container mt-5'>
<div className='row mt-5'>
  <div className='col-md-4 '>
    <p className='in-demand'>In Demand</p>
    <h2 className='in-1'>Popular Courses</h2>
   
  </div>
  <div className='col-md-2'>
      <h1>
        <span className="pipe"></span>
      </h1>
    </div>
    <div className='col-md-2'>
<h2 className=' in-2'>Nursing</h2>
    </div>
    <div className='col-md-2'>
<h2 className=' in-3'>B. TECH
</h2>
    </div>
    <div className='col-md-2'>
<h2 className=' in-4'>M.B.A</h2>
    </div>

</div>
<div className='row mt-5'>
<h2 className=' science-css'>Science Stream Courses</h2>
<p className='science-p mt-2'>
Higher education aspirants from the Science Stream have the opportunity to pursue fields such as medicine, dentistry, engineering, architecture, nursing, pharmacy, paramedical sciences, and physiotherapy. Each of these disciplines provides specialized courses for those completing their 12th grade or postgraduate studies, with a strong focus on job readiness. Graduates from these programs often receive attractive job offers from leading companies, ensuring swift employment in their chosen fields.

</p>
<div className='col-md-12 align-right'>
<Link to="/contact" className="   course-b " >
    ENQUIRE NOW <i className="bi bi-caret-right-fill course-b1  "></i>
  </Link>
</div>
 
</div>
<Sciencecourse/>
        <div className='row mt-5'>
<h2 className=' science-css'>Commerce Stream Courses</h2>
<p className='science-p mt-2'>
Commerce stream students are fortunate to have the flexibility to choose from disciplines like Computer Applications, Management, Economics, and Accountancy. Each of these fields offers specialized courses after the 12th grade and postgraduate options after graduation, leading to jobs and recognition as highly-skilled professionals. Completing these programs opens doors to immediate self-employment with lucrative earnings and also provides strong employment opportunities in various organizations.

</p>
<div className='col-md-12 align-right'>
<Link to="/contact" className="   course-b " >
    ENQUIRE NOW <i className="bi bi-caret-right-fill course-b1  "></i>
  </Link>
</div>
 
</div>
<Commerce/>
<div className='row mt-5'>
<h2 className=' science-css'>Arts Stream Courses</h2>
<p className='science-p mt-2'>
Aspirants from the Arts Stream can pursue disciplines like Visual Arts, Mass Communication, Vocational Studies, Design, or Law, with specialized courses available after 12th grade or postgraduate studies. These programs lead to self-employment and professional recognition. Graduates often find immediate self-employment, lucrative earnings, and job opportunities with various organizations.
</p>
<div className='col-md-12 align-right'>
<Link to="/contact" className="   course-b " >
    ENQUIRE NOW <i className="bi bi-caret-right-fill course-b1  "></i>
  </Link>
</div>
 
</div>
<Arts/>
        </div>
        
      </div>
   
    
    </div>
  );
};

export default Courses;
