import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  return (
    <div>
      
              <nav className="navbar navbar-expand-lg navbar-light  ">
                <div className="container">
                <img src="StudentCareer.png" height="90px" className="mt-2" width="200px"  />
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-auto">
                      <li className="nav-item">
                        <Link className="nav-link active ms-3" aria-current="page" to="/">Home</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link active ms-3" to="/about">About us</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link active ms-3" to="/services">Services</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link active ms-3" to="/courses">Courses</Link>
                      </li>
                      
                      <li className="nav-item">
                        <Link className="nav-link active ms-3" to="/contact">Contact Us</Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
         
  );
}

export default Navbar;
