import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './Component/Navbar/Navbar';
import Topnav from './Component/Topnav/Topnav';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import Contact from './Component/Contact/Contact';
import Distance from './Component/Distancelearning/Distance';
import Footer from './Component/Footer/Footer';
import Courses from './Component/Courses/Courses';
import Services from './Component/Services/Services';
import Servicestudent from './Component/Services/Servicestudent';

import Whatsapp from './Component/Whatsapp';
function App() {
  return (
    <div>
      <Router>
        <Topnav/>
      <Navbar/>
        <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="about" element={<About/>}/>
        <Route path="contact" element={<Contact/>}/>
        <Route path="distance" element={<Distance/>}/>
        <Route path="courses" element={<Courses/>}/>
        <Route path="servicestudent" element={<Services/>}/>
        <Route path="services" element={<Servicestudent/>}/>
        
</Routes>

 {/* WhatsApp button */}
 <Whatsapp />
<Footer/>
        </Router>
     
    </div>
  );
}

export default App;
