import React from 'react';
import "./sciencecourse.css";
import { Link } from "react-router-dom";
const Arts = () => {
  return (
    <div>
       <div class="container mt-5">
  <div class="row">
    
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="ba.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Arts</h4>
        <p class=" coursecard-h-p11 ">B.A, M.A.</p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>A Bachelor of Arts (B.A.) typically takes 3 years to complete, while a Master of Arts (M.A.) usually requires an additional 2 years.
        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="law.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Law</h4>
        <p class=" coursecard-h-p11 ">L.L.B [B.A, B.B.A, B.Com, B.Sc] </p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>An L.L.B typically takes 3 years and can be pursued after a B.A., B.B.A., B.Com., or B.Sc. degree, offering legal specialization.

        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="arts1.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Design</h4>
        <p class=" coursecard-h-p11 ">B. Design, M. Design.</p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>A B.Design (Bachelor of Design) typically takes 3-4 years, while an M.Design (Master of Design) usually requires 2 years.

        </p>    
  </div>
</div>
       
    </div>
    <div class="col-md-3">
       <div class="card mb-4 science-card" >
 <img src="arts.jpg" class="d-block w-100" alt="..."/>
  <div class="card-body ">
   <h4  class="  coursecard-h11 mt-2 ">Ph.D</h4>
        <p class=" coursecard-h-p11 ">Ph.d,B.Ed(Arts)</p>
        <p className='coursecard-h-p1'>
      <span className='duration'>Duration: </span>A Ph.D.  varies but generally takes 3-5 years, depending on the field and research. A B.Ed. (Arts)  typically takes 2 years.

        </p>    
  </div>
</div>
       
    </div>
    
    
  
    
  </div>
  
 </div>
    
    </div>
  );
};

export default Arts;
