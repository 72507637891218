import React from 'react';
import Slider from "react-slick";
import "./TestimonialsSlider.css";

const testimonials = [
  {
    name: "Abhinav Rawat",
    feedback: "A student career coach provides invaluable guidance to help students identify the right college for their academic and career goals. They offer personalized advice on selecting institutions that align with the student’s interests, strengths, and long-term plans. By understanding each student's unique profile, they simplify the often overwhelming college search process. Their expertise ensures students consider important factors like campus culture, available programs, and career opportunities. Ultimately, the coach helps students make informed decisions that set them on the path to future success.",
    role: "MBA Student",
    image: "testi2.jpeg", // Add the image URL here
  },
  {
    name: "Aryan Shukla",
    feedback: "Working with a student career coach was a game-changer for my college search. They took the time to understand my interests and goals, helping me find colleges that truly fit my aspirations. Their in-depth research and personalized recommendations were invaluable. They guided me through each step, from researching institutions to finalizing my choice, and their support made the whole process smoother and more manageable. I’m now at a university that feels like the perfect fit for me, both academically and personally, thanks to their expert advice. I feel well-prepared for the future and confident in my choice, all thanks to their dedicated guidance!",
    role: "B.tech Student",
    image: "testi3.jpeg", // Add the image URL here
  },
  {
    name: "Anamika Joshi",
    feedback: "The student career coach provided exceptional support in finding the right college for me. They offered personalized recommendations and insights that I wouldn’t have found on my own. Their extensive knowledge about various institutions, programs, and admissions criteria was incredibly valuable. They took the time to understand my unique needs and preferences, ensuring that every option we explored was a good match. Their guidance helped me make a well-informed decision and eased the stress of the college search process. I highly recommend their services to any student navigating the college search process they truly make a difference!",
    role: "B.Sc Nursing Student",
    image: "testi5.jpeg", // Add the image URL here
  },
];

const Testi = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, // This disables the navigation arrows
  };

  return (
    <div className="slider-container">
      <h2 className='testih'>Student <span className='testip'> Testimonials</span></h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-image-container">
              <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
            </div>
            <p className="feedback">"{testimonial.feedback}"</p>
            <h3 className='name1'>{testimonial.name}</h3>
            <span>{testimonial.role}</span>
          </div>
        ))}
      </Slider>
      <div className="dots-background">
        <Slider {...settings} />
      </div>
    </div>
  );
};

export default Testi;
